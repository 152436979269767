import React from 'react'
import styled from 'styled-components'

const Background = styled.div`
    border-radius: 50%;
    background-color: ${p => p.theme.light};
    cursor: pointer;
    :hover div {
        color: ${p => p.theme.primaryHover};
    }
`

const Icon = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    color: ${p => p.theme.primary};
    font-size: 1.5em;
    align-items: center;
    justify-content: center;
`

export default function IconButton({style, icon, onClick}) {
    return (
        <Background onClick={onClick} style={style}>
            <Icon className='material-icons'>{icon}</Icon>
        </Background>
    )
}
