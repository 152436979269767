import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import backgroundImage from './../Graphics/smallbg.png'

import Button from './Button'
import InputField from './InputField'

const Holder = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
`

const Background = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .6);
`

const Panel = styled.div`
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    margin-left: -14em;
    position: absolute;
    overflow: hidden;
    width: 28em;

    background-color: ${p => p.theme.dark};
    background-image: url(${backgroundImage});
    background-position: 0 -8em;

    border-radius: ${p => p.theme.roundCorners};

    display: grid;
    grid-template-rows: 9em auto;
`

const WhitePart = styled.div`
    background-color: ${p => p.theme.light};
    display: grid;
    grid-template-rows: auto 2.5em;
    grid-gap: 1em;
    padding: 2.5em;
`

const Title = styled.div`
    font-weight: bold;
    text-align: center;
    font-size: 2em;
    color: ${p => p.theme.dark};
`

const Description = styled.div`
    padding: 1em 0;
    text-align: center;
`

const Buttons = styled.div`
    display: grid;

    grid-template-columns: ${p => p.double ? 'auto auto' : 'auto'};
    grid-gap: 1em;
    /* padding: 1em; */
`

const Icon = styled.div`
    color: ${p => p.theme.light};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.5em;
`

export default function Popup(props) {
    const [state, reducer] = useState({
        inputValue: '',
        setInputValue: i => { state.inputValue = i; state.save(); },
        save: () => reducer({...state})
    })

    const inputField = props.inputType ?
        (<InputField onChange={state.setInputValue} placeholder={props.placeholder}/>) : null;

    const confirmHandler = () => {
        if (props.inputType === true) {
            props.confirmHandler(state.inputValue)
        }
        else props.confirmHandler()
    }

    const keyDownHandler = event => {
        if (event.key === 'Enter') confirmHandler() 
        if (event.key === 'Escape') props.cancelHandler()
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        };
    })

    return (
        <Holder>
            <Background onClick={props.cancelHandler}/>
            <Panel>
                <Icon className='material-icons'>{props.icon}</Icon>
                <WhitePart>
                <div>
                    <Title>{props.title}</Title>
                    <Description>{props.description}</Description>
                    {inputField}
                </div>
                <Buttons double>
                    <Button onClick={props.cancelHandler} dark>Cancel</Button>
                    <Button onClick={confirmHandler}>Confirm</Button>
                </Buttons>
                </WhitePart>
            </Panel>
        </Holder>
    )
}