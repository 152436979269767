import React, { useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import NotificationsContext from './../Context/NotificationsContext'

const Holder = styled.div`
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: middle;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
    transition: 1s;
`

const hideAnim = keyframes`
    0% {
        opacity: 1;
        transform-origin: 0 0;
    }
    100% {
        opacity: 0;
        transform-origin: 0 -50%;
    }
`

const Tag = styled.div`
    margin: auto;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.rounderCorners};
    text-align: center;
    padding: .5em 3em;
    color: ${p => p.theme.dark};
    box-shadow: ${p => p.theme.shadow};

    pointer-events: all;
    cursor: pointer;

    animation: ${p => p.state ? '' : hideAnim} 1s ease-in-out forwards;
`

export default function Notifications() {
    const notificationsContext = useContext(NotificationsContext)
    return (
        <Holder>
            { Object.values(notificationsContext.notifications)
                .reverse()
                .map(n => (<Tag state={n.state}>{n.text}</Tag>)) } 
            </Holder>
    )
}