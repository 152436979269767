import React, { useState } from 'react'
import Firebase, { paths } from './../Logic/Firebase'

const User = React.createContext({})

export function UserContext({ children }) {
    const [state, reducer] = useState({
        user: {},
        designs: {},
        selectedDesign: {},
        setSelectedDesign: d => { state.selectedDesign = d; state.save(); },
        editDesign: d => { state.designs[d.Id] = d; state.save(); },
        setUser: u => { state.user = u; state.save(); state.setDesigns(); },
        addDesign: d => {
            if (!d.Id) return
            // Local State.
            state.user.Designs[d.Id] = d.Id
            state.designs[d.Id] = d
            state.save()
            // Firebase
            Firebase.database().ref(`${paths.Designs}/${d.Id}`).set(d)
            Firebase.database().ref(`${paths.Users}/${state.user.Id}/${paths.Designs}/${d.Id}`).set(d.Id)
        },
        deleteDesign: d => {
            if (!d.Id) return
            // Local State.
            delete state.user.Designs[d.Id]
            delete state.designs[d.Id]
            state.save()
            // Firebase
            Firebase.database().ref(`${paths.Designs}/${d.Id}`).remove()
            Firebase.database().ref(`${paths.Users}/${state.user.Id}/${paths.Designs}/${d.Id}`).remove()
        },
        setDesigns: () => {
            if (!state.user.Designs) return
            Object.values(state.user.Designs).forEach(id => {
                Firebase.database().ref(`${paths.Designs}/${id}`).once('value').then(snapshot => {
                    state.designs[id] = snapshot.val();
                    state.save();
                })
            })
        },
        save: () => reducer({...state}),
    });

    return (
        <User.Provider value={state}>
            { children }
        </User.Provider>
    )
}

export default User;