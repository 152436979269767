import React from 'react'
import Unity, { UnityContent } from 'react-unity-webgl'


export default class UnityCanvas extends React.Component {
    constructor(props) {
        super(props)
        this.unityContent = new UnityContent(
            'Build/InViewer.json',
            'Build/UnityLoader.js'
        )

        this.unityContent.on('SendToReact', message => {
            let payload = JSON.parse(message)
            this.props.state.onUnityMessage(payload);
        });

        this.props.state.setSendToUnity((type, data) => {
            console.log(JSON.stringify({Type: type, Data: data}))
            this.unityContent.send('WebCommunication(Clone)', 'InvokeJavascriptEvent', JSON.stringify({Type: type, Data: data}))
        })

        this.unityContent.on('progress', props.state.setProgress)
    }

    render() {
        return (
                <Unity unityContent={this.unityContent} />
        )
    }
}