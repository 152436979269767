import React from 'react'
import styled from 'styled-components'

import backgroundImage from './../Graphics/background.png'

const Holder = styled.div`
    display: grid;
    grid-template-rows: auto 1em;
    cursor: pointer;
    :hover {
        div:last-child {
            color: ${p => p.theme.primary};
        }
    }
`

const Background = styled.div`
    background-image: url('https://firebasestorage.googleapis.com/v0/b/inviewer-89ad5.appspot.com/o/Images%2F${p => p.image}?alt=media'), url(${backgroundImage});
    background-size: cover, 300%;
    background-position: 0 0, 10% 10%;
    border-radius: 1em;
    padding-top: 50%;
    box-shadow: ${p => p.theme.shadow};
`

const Title = styled.div`
    color: ${p => p.theme.dark};
    font-weight: bold;
    margin-top: .5em;
    margin-left: .5em;
`

export default function Design(props) {
    return (
        <Holder onClick={() => props.onClick(props.design)}>
            <Background image={props.design.Id}></Background>
            <Title>{props.design.Name}</Title>
        </Holder>
    )
}
