import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import InputField from './../Components/InputField'
import Button from './../Components/Button'
import Loading from './../Components/Loading'
import Popup from './../Components/Popup'

import logoImage from './../Graphics/inviewerRocketTitle.svg'
import backgroundImage from './../Graphics/background.png'

import { logIn, sendResetPasswordEmail, register } from './../Logic/Firebase'
import User from './../Context/UserContext'
import Notifications from './../Context/NotificationsContext'

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(222,182,214);
    background: linear-gradient(297deg, rgba(222,182,214,1) 0%, rgba(255,242,213,1) 100%);
    display: grid;
    grid-template-columns: auto 50em auto;
    grid-template-rows: auto 25em auto;
`

const Panel = styled.div`
    position: relative;
    margin: auto;
    width: 50em;
    height: 25em;
    border-radius: ${p => p.theme.roundCorners};
    background-image: url(${backgroundImage});
    background-size: cover;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
    overflow: hidden;

    box-shadow: ${p => p.theme.shadow};
`

const Logo = styled.div`
    position: absolute;
    top: -8em;
    left: 50%;
    margin-left: -4em;
    width: 8em;
    height: 8em;
    mask: url(${logoImage});
    mask-size: cover;
    background-color: ${p => p.theme.primary};
`

const SidePanel = styled.div`
    padding: 2em;
    position: relative;
    top: ${props => props.show ? 0 : '100%'};
    background-color: ${p => p.theme.light};
    width: 100%;
    height: 100%;
    transition: .3s;
    grid-column: ${p => p.right ? '2/3' : '1/2'};
    grid-row: 1/2;
`

const Title = styled.div`
    color: ${p => p.theme.medium};
    font-size: 1.5em;
`

const Text = styled.div``

const TextButton = styled.div`
    color: ${p => p.light ? p.theme.light : p.theme.primary};
    cursor: pointer;
    :hover {
        color: ${p => p.theme.primaryHover};
    }
`

const Row = styled.div`
    position: absolute;
    bottom: 1.5em;
    right: 0;
    left: 0;
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 1em;
`

const FeedbackText = styled.div`
    color: ${p => p.theme.dark};
    margin-top: .5em;
    text-align: center;
    font-weight: bold;
    font-size: .8em;
`

function Login() {
    const userContext = useContext(User)
    const notificationsContext = useContext(Notifications)
    const history = useHistory()
    const [state, reducer] = useState({
        view: 0,
        loading: false,
        email: '',
        password: '',
        feedback: '',
        registerEmail: '',
        registerPass: '',
        setFeedback: f => { state.feedback = f; state.save(); },
        setView: view => { state.view = view; state.save(); },
        setEmail: email => { state.email = email; state.save(); },
        setPassword: pass => { state.password = pass; state.save(); },
        setRegisterEmail: e => { state.registerEmail = e; state.save(); },
        setRegisterPass: p => { state.registerPass = p; state.save(); },
        setLoading: v => { state.loading = v; state.save(); },
        save: () => reducer({...state}),
    })

    const [popup, setPopup] = useState({
        active: false,
        setActive: s => { popup.active = s; popup.save(); },
        icon: '',
        title: '',
        description: '',
        confirmHandler: () => { },
        cancelHandler: () => { },
        inputType: false,
        placeholder: '',
        save: () => setPopup({...popup})
    })

    const forgotYourPasswordHandler = () => {
        popup.icon = 'menu_book'
        popup.title = 'Reset Password'
        popup.description = 'Enter your email below, a reset password email will be send'
        popup.cancelHandler = () => {
            popup.setActive(false)
        }
        popup.confirmHandler = email => {
            popup.cancelHandler()
            sendResetPasswordEmail(email, response => {
                notificationsContext.notify(response.value)
            })
        }
        popup.inputType = true
        popup.placeholder = 'your email...'
        popup.setActive(true)
    }

    const registerHandler = () => {
        state.setLoading(true);
        register(state.registerEmail, state.registerPass, payload => {
            if (payload.type === 0) logInHandler(state.registerEmail, state.registerPass)
            else {
                notificationsContext.notify(payload.value)
                state.setLoading(false)
            }
        })
    }

    const logInHandler = (email, pass) => {
        state.setLoading(true);
        logIn(email, pass, payload => {
            state.setLoading(false);
            if (payload.type === 0) { 
                userContext.setUser(payload.value)
                history.push('menu')
            }
            if (payload.type === 1) state.setFeedback(payload.value)
        })
    }

    const keyDownHandler = event => {
        if (event.key === 'Enter' && state.view===0) logInHandler(state.email, state.password); 
    }

    return (
        <Background>
            <div style={{gridColumn: '2/3', gridRow: '2/3', position: 'relative'}}>
                <Logo />
                <Panel onKeyPress={keyDownHandler}>
                    <SidePanel right show={state.view===0}>
                        <Title>Welcome Back!</Title>
                        <Title style={{marginBottom: '.5em'}}>Login to your account</Title>
                        <InputField id='email' type='email' onChange={state.setEmail} placeholder='Your email'/>
                        <InputField id='password' type='password' onChange={state.setPassword} placeholder='Your password'/>
                        <TextButton onClick={forgotYourPasswordHandler} style={{textAlign: 'center', margin: '1em 0'}}>Forgot your Password?</TextButton>
                        <Button style={{marginTop:'1em'}} onClick={() => logInHandler(state.email, state.password)}>Sign In</Button>
                        <FeedbackText>{state.feedback}</FeedbackText>
                        <Row><Text>Don't have an account?</Text><TextButton onClick={() => state.setView(1)}>Sign Up</TextButton></Row>
                    </SidePanel>
                    <SidePanel show={state.view===1}>
                        <Title style={{marginBottom: '1em'}}>Create your account now</Title>
                        <InputField onChange={state.setRegisterEmail} placeholder='Your email'></InputField>
                        <InputField type='password' onChange={state.setRegisterPass} placeholder='Your password'></InputField>
                        <Button onClick={registerHandler} style={{marginTop: '1em'}}>Sign Up</Button>
                        <Row><Text>Already have an account?</Text><TextButton onClick={() => state.setView(0)}>Sign In</TextButton></Row>
                    </SidePanel>
                    {state.loading && <Loading></Loading>}
                </Panel>
                {!state.loading && <TextButton style={{textAlign:'center', marginTop: '1.5em'}}><b>Continue as a Guest</b></TextButton>}
            </div>
            { popup.active && (<Popup 
                icon={popup.icon}
                description={popup.description}
                title={popup.title}
                inputType={popup.inputType}
                placeholder={popup.placeholder}
                cancelHandler={popup.cancelHandler}
                confirmHandler={popup.confirmHandler}
            />)}
        </Background>
    )
}

export default Login