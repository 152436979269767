import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { key as newFirebaseKey } from 'firebase-key'
import User from './../Context/UserContext'
import Notifications from './../Context/NotificationsContext'
import logoImage from './../Graphics/icon.png'
import IconButton from './../Components/IconButton'
import DesignConfigPanel from './../Components/DesignConfigPanel'
import Design from './../Components/Design'

const Layout = styled.div`
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 4em auto 4em;
    background-color: ${p => p.theme.light};
`

const Logo = styled.div`
    background-image: url(${logoImage});
    background-size: cover;
    margin: .5em;
`

const LeftBar = styled.div`
    display: grid;
    grid-template-rows: 4em auto 4em;
    background-color: ${p => p.theme.primary};
`

const RigthBar = styled.div`
    display: grid;
    grid-template-rows: 4em 4em auto 4em;
`

const ProfilePic = styled.div`
    margin: .9em;
    border-radius: 50%;
    box-shadow: ${p => p.theme.shadow};
`

const DesignsHolder = styled.div`
    padding: 1.5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    grid-gap: 1.5em;
    width: 100%;
`

const Holder = styled.div`
    overflow: scroll;
`

export default function Menu() {
    const [state, reducer] = useState({
        selectedDesign: {},
        setDesign: d => { state.selectedDesign = d; state.save(); state.setDesignPanel(true)},
        designPanel: false,
        setDesignPanel: s => { state.designPanel = s; state.save(); },
        save: () => reducer({...state}),
    })
    const userContext = useContext(User)
    const notificationsContext = useContext(Notifications)

    const history = useHistory();

    // Check for user.
    if (Object.keys(userContext.user).length === 0) {
        // Go back to login.
        notificationsContext.notify('Session expired')
        history.push('/');
    }

    const onDesignClick = design => {
        state.setDesign(design)
    }

    const getDesigns = () => {
        if (userContext.designs)
            return Object.values(userContext.designs).map((d, key) => (<Design key={key} design={d} onClick={onDesignClick}/>))
        else return <div>No designs</div>
    }

    const newDesignHandler = () => {
        let design = { 
            Id: newFirebaseKey(),
            Size: { x: 300, y: 10, z: 300}
        }
        userContext.addDesign(design)
        state.setDesign(design)
    }

    const duplicateDesignHandler = d => {
        let design = {...d}
        design.Id = newFirebaseKey()
        design.Name += ' Clone'
        userContext.addDesign(design)
        state.setDesignPanel(false)
    }

    return (
        <Layout>
            <LeftBar>
                <Logo />
                <div></div>
                <IconButton style={{margin:'.9em'}} icon='brightness_low' />
            </LeftBar>
            <Holder><DesignsHolder>{getDesigns()}</DesignsHolder></Holder>
            <RigthBar>
                <ProfilePic />
                <IconButton style={{margin:'.9em'}} icon='notifications' />
                <div></div>
                <IconButton onClick={newDesignHandler} style={{margin:'.9em'}} icon='add_circle' />
            </RigthBar>
            {
                state.designPanel &&
                <DesignConfigPanel 
                    duplicateDesignHandler={duplicateDesignHandler}
                    design={state.selectedDesign}
                    onClose={() => state.setDesignPanel(false)}/>
            }
        </Layout>
    )
}