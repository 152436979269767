import React, { useState, useContext, useRef } from 'react'
import styled from 'styled-components'

import User from './../Context/UserContext'
import UnityCanvas from './Unity'
import FirstPersonView from './../UnityUIs/FirstPersonView'
import EditorView from './../UnityUIs/EditorView'

import Loading from './../Components/Loading'
import backgroundImage from './../Graphics/background.png'

import Popup from './../Components/Popup'

const LoadingHolder = styled.div`
    background-image: url(${backgroundImage});
    background-size: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`

const Text = styled.div`
    color: ${p => p.theme.light};
    position: absolute;
    width: 100%;
    top: 54%;
    text-align: center;
`

const Holder = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`

export default function UnityContainer(props) {
    const userContext = useContext(User)

    const uiRef = useRef();

    const [state, reducer] = useState({
        progress: 0,
        assetsProgress: 0,
        setProgress:  p => { state.progress = p; state.save(); },
        setAssetsProgress: p => { state.assetsProgress = p; state.save(); },

        unityUI: -1,
        setUnityUI: u => { state.unityUI = u; state.save(); },

        onUnityMessage: m => onUnityMessage(m),

        sendToUnity: (type, data) => {},
        setSendToUnity: f => { state.sendToUnity = f; state.save(); },

        save: () => reducer({...state}),
    });

    const [popup, setPopup] = useState({
        active: false,
        setActive: s => { popup.active = s; popup.save(); },
        icon: '',
        title: '',
        description: '',
        confirmHandler: () => { },
        cancelHandler: () => { },
        save: () => setPopup({...popup})
    })

    const backHandler = () => {
        popup.icon = 'exit_to_app'
        popup.title = 'Quit scene'
        popup.description = 'You are about to quit the scene, back to main menu'
        popup.cancelHandler = () => {
            popup.setActive(false)
        }
        popup.confirmHandler = () => {
            props.history.push('/menu')
        }
        popup.setActive(true)
    }

    const onUnityMessage = message => {
        if (message.Type === 'DesignLoadingProgress') state.setAssetsProgress(message.Data)
        if (message.Type === 'GoToPage') {
            if (message.Data === '/menu') backHandler() 
        }
        if (message.Type === 'AskForDesign') state.sendToUnity('AskForDesign', userContext.selectedDesign.Id) 
        if (message.Type === 'ReactViewType') state.setUnityUI(message.Data) 

        if (uiRef.current != undefined) uiRef.current.onUnityMessage(message);
    }

    const isLoading = () => (state.progress < 1 || state.assetsProgress < 1);

    const getDisplay = () => {
        if (isLoading()) {
            return (
                <LoadingHolder>
                <Loading noBackground/>
                {(state.progress < 1) && <Text>{Math.floor(state.progress * 100)}%</Text>}
                {(state.progress === 1) && <Text>Downloading assets... {Math.floor(state.assetsProgress * 100)}%</Text>}
                </LoadingHolder>)
        }

        else {
            if (state.unityUI == 1) return <FirstPersonView ref={uiRef}/>
            if (state.unityUI == 0) return <EditorView homeHandler={backHandler} sendToUnity={state.sendToUnity} ref={uiRef}/>
        }
    }

    return (
        <Holder>
            <UnityCanvas state={state}/>
            { getDisplay() }
            { popup.active && (<Popup 
                icon={popup.icon}
                description={popup.description}
                title={popup.title}
                cancelHandler={popup.cancelHandler}
                confirmHandler={popup.confirmHandler}
            />)}
        </Holder>
    )
}