import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Login from './Pages/Login'
import Menu from './Pages/Menu'
import UnityContainer from './Pages/UnityContainer'

import User from './Context/UserContext';
import Notifications from './Components/Notifications' 

function App() {
  const userContext = useContext(User)
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact={true} path='/'><Login /></Route>
          <Route path='/menu'><Menu /></Route>
          <Route path='/design' component={UnityContainer}/>
        </Switch>
      </Router>
      <Notifications />
    </React.Fragment>
  );
}

export default App;