import React from 'react'
import styled, { css } from 'styled-components'

const Holder = styled.div`
    display: flex;
    gap: .8em;

    ${p => p.isButton && css`
        :hover div {
            color: ${p => p.theme.primary};
        }
        cursor: pointer;
    `}

    padding: .6em 0;
`

const Text = styled.div`
    float: left;
    color: ${props => props.theme.dark};
    display: flex;
    align-items: center;
`

function IconText(props) {
    return (
        <Holder isButton={props.isButton} style={props.style} onClick={props.onClick}>
            <Text className='material-icons'>{props.icon}</Text>
            <Text><b>{props.children}</b></Text>
        </Holder>        
    )
}

export default IconText
