import React from 'react';
import styled, { css } from 'styled-components';

const Background = styled.div`
    border-radius: ${props => props.theme.rounderCorners};
    background-color: ${props => props.secondary ? 
        props.theme.light : props.dark ? props.theme.darkerPrimary : props.theme.primary};

    ${props => props.secondary && css`
        border: .1em solid ${props => props.theme.primary};
    `}

    color: ${props => props.secondary ? props.theme.dark : props.theme.light};

    font-size: ${props => props.theme.titleSize};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    padding: .5em;
    cursor: pointer;

    :hover {
        background-color: ${p => p.dark ? p.theme.primary : p.theme.primaryHover};
    }
`

export default function Button({secondary, children, onClick, style, dark}) {
    return (
        <Background style={style} dark={dark} secondary={secondary} onClick={onClick}>
            {children}
        </Background>
    )
}