export const Main = {
    primary: '#a032a8',
    darkerPrimary: '#1A1132',
    primaryHover: '#da40e6',
    light: '#ffff',
    darkerLight: '#f5f2fc',
    dark: '#272838',
    medium: 'gray',
    roundCorners: '2em',
    rounderCorners: '4em',
    shadow: '0 .1em .2em rgb(1,1,1,.1)',
}