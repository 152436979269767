import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

// Theme.
import { ThemeProvider } from 'styled-components'
import { Main } from './Theme'

// Contexts.
import { UserContext } from './Context/UserContext'
import { NotificationsContext } from './Context/NotificationsContext'

function Holder() {
    return (
        <NotificationsContext>
            <UserContext>
                <ThemeProvider theme={ Main }>
                    <App />
                </ThemeProvider>
            </UserContext>
        </NotificationsContext>
    )
}

ReactDOM.render(<Holder />, document.getElementById('root'));