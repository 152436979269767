import React from 'react'
import styled, { keyframes } from 'styled-components'

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${p => p.noBackground ? '' : 'rgb(0,0,0,.2)'};

    display: flex;
    justify-content: center;
`

const animA = keyframes`
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`
const animC = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
`
const animB = keyframes`
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
`

const Loader = styled.div`
    display: inline-block;
    margin: auto;
    position: relative;
    width: 64px;
    height: 64px;

    div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    div:nth-child(1) {
        left: 6px;
        animation: ${animA} 0.6s infinite;
    }
    div:nth-child(2) {
        left: 6px;
        animation: ${animB} 0.6s infinite;
    }
    div:nth-child(3) {
        left: 26px;
        animation: ${animB} 0.6s infinite;
    }
    div:nth-child(4) {
        left: 45px;
        animation: ${animC} 0.6s infinite;
    }
`

export default function Loading({noBackground}) {
    return (
        <Background noBackground={noBackground}>
            <Loader><div /><div /><div /><div /></Loader>
        </Background>
    )
}