import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import backgroundImage from './../Graphics/background.png'

import Button from './Button'
import IconButton from './IconButton'
import IconText from './IconText'
import Popup from './Popup'

import User from './../Context/UserContext'
import Notifications from './../Context/NotificationsContext'
import Firebase, { paths } from '../Logic/Firebase'

const Holder = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
`

const Background = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: rgb(0, 0, 0, .6);

    display: grid;
    grid-template-columns: auto 50em auto;
    grid-template-rows: auto 25em auto;
`

const Panel = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12.5em;
    margin-left: -25em;
    width: 50em;
    height: 25em;
    border-radius: ${p => p.theme.roundCorners};
    background-image: url(${backgroundImage});
    background-size: 130%;
    background-position: 220% 80%;

    overflow: hidden;
`

const InfoPanel = styled.div`
    padding: 2em;
    position: absolute;
    right: ${p => p.config ? '50%' : 0};
    width: ${p => p.config ? '30%' : '50%'};
    height: 100%;
    background-color: ${p => p.theme.light};

    transition: .3s;

    display: grid;
    grid-gap: .5em;
    grid-template-rows: auto 2.2em 2.2em;
    box-sizing: border-box;
`

const ConfigPanel = styled.div`
    padding: 2em;
    background-color: ${p => p.theme.darkerLight};
    position: absolute;
    right: ${p => p.config ? 0 : '-50%'};
    width: 50%;
    height: 100%;

    transition: .3s;
`

const Title = styled.div``

const Description = styled.div`
    border-bottom: .08em solid ${p => p.theme.medium};
    margin-right: 1em;
    width: 100%;
`

const TextArea = styled.textarea`
    border: none;
    resize: vertical;
    :hover {
        border: .01em solid ${p => p.theme.dark};
    }
    width: 100%;
    font-size: 1.5em;
    color: ${p => p.theme.dark};
    padding: .5em;
    max-height: 8em;
`

const Input = styled.input`
    border: none;
    :hover {
        border: .01em solid ${p => p.theme.dark};
    }

    color: ${p => p.theme.medium};
    font-size: 1.8em;

    width: 100%;
    height: 100%;
`

const Text = styled.div`
    color: ${p => p.theme.dark};
    padding: 1em 0;
`

const ButtonHolder = styled.div`
    position: absolute;
    left: 50%;
    margin-left: -2.4em;
    top: 50%;
    margin-top: -15em;
    display: grid;
    grid-template-columns: 2em 2em;
    grid-gap: .8em;
    height: 2em;
    width: 4.8em;
`

export default function DesignConfigPanel(props) {
    const userContext = useContext(User)
    const notificationsContext = useContext(Notifications)
    const history = useHistory()
    const [state, reducer] = useState({
        config: false,
        setConfig: v => { state.config = v; state.save(); },
        save: () => reducer({...state}),
    }) 

    const [popup, setPopup] = useState({
        active: false,
        setActive: s => { popup.active = s; popup.save(); },
        icon: '',
        title: '',
        description: '',
        confirmHandler: () => { },
        cancelHandler: () => { },
        save: () => setPopup({...popup}),
    })

    const shareSceneHandler = () => {
        history.push(`/design?first-person=${props.design.Id}`)
    }

    const editSceneHandler = () => {
        userContext.setSelectedDesign(props.design)
        history.push('/design')
    }

    const editDesign = (key, value) => {
        let design = props.design
        design[key] = value
        userContext.editDesign(design)
        if (design.Id && key) Firebase.database().ref(`${paths.Designs}/${design.Id}/${key}`).set(value)  
    }

    const deleteDesignHandler = () => {
        popup.icon = 'delete'
        popup.title = 'Delete Design'
        popup.description = `Poor ${props.design.Name} is going to the garbage can :(`
        popup.cancelHandler = () => {
            popup.setActive(false)
        }
        popup.confirmHandler = () => {
            props.onClose()
            notificationsContext.notify(`Design ${props.design.Name} deleted`)
            userContext.deleteDesign(props.design)
        }

        popup.setActive(true)
    }

    return (
        <Holder>
            <Background onClick={props.onClose}/>
            <ButtonHolder>
                <IconButton onClick={() => state.setConfig(!state.config)} icon='more_horiz'/>
                <IconButton onClick={props.onClose} icon='close'/>
            </ButtonHolder>

            <Panel>
                <ConfigPanel config={state.config}>
                    <IconText icon='home'>Project Settings</IconText>
                    <IconText
                    onClick={() => props.duplicateDesignHandler(props.design)}
                    style={{paddingLeft:'.8em'}}
                    isButton
                    icon='file_copy'>Duplicate</IconText>

                    <IconText
                    style={{paddingLeft:'.8em'}}
                    isButton
                    icon='share'
                    onClick={shareSceneHandler}>Share</IconText>

                    <IconText
                    onClick={deleteDesignHandler}
                    style={{paddingLeft:'.8em'}}
                    isButton
                    icon='delete'>Delete</IconText>
                </ConfigPanel>
                <InfoPanel config={state.config}>
                    <div>
                        <Title>
                            <Input 
                                value={props.design.Name}
                                placeholder={!props.design.Name && 'Enter Name'}
                                onChange={e => editDesign('Name', e.target.value)}
                            />
                        </Title>
                        <Description>
                            <TextArea 
                                value={props.design.Description}
                                placeholder={!props.design.Description && 'No Description'}
                                onChange={e => editDesign('Description', e.target.value)}
                            />
                        </Description>
                        <Text>Last edit: <b>Yesterday</b></Text>
                    </div>
                    <Button onClick={shareSceneHandler}>View Scene</Button>
                    <Button onClick={editSceneHandler}>Open Scene Editor</Button>
                </InfoPanel>
            </Panel>

            { popup.active && (<Popup 
                icon={popup.icon}
                description={popup.description}
                title={popup.title}
                cancelHandler={popup.cancelHandler}
                confirmHandler={popup.confirmHandler}
            />)}
        </Holder>
    )
}