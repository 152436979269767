const firebaseConfig = {
    apiKey: "AIzaSyBqmvg5FrOQlz5bJ9rBWuG7RyEbqsBzAfI",
    authDomain: "inviewer-89ad5.firebaseapp.com",
    databaseURL: "https://inviewer-89ad5.firebaseio.com",
    projectId: "inviewer-89ad5",
    storageBucket: "inviewer-89ad5.appspot.com",
    messagingSenderId: "743091345233",
    appId: "1:743091345233:web:b67d543c5e970f353da570",
    measurementId: "G-SC5WMDG12P"
};

const Firebase = require('firebase/app')
require('firebase/auth')
require('firebase/database')

export const paths = {
    Users: 'Users',
    Objects: 'Objects',
    Designs: 'Designs',
}

Firebase.initializeApp(firebaseConfig)

export function sendResetPasswordEmail(email, payload) {
    Firebase.auth().sendPasswordResetEmail(email).then(function() {
        payload({type: 0, value: "Email has been sent"})
        }).catch(function(error) {
            payload({type: 1, value: error.message})
        });
}

export function register(email, password, payload) {
    Firebase.auth().createUserWithEmailAndPassword(email, password).then(credentials => {
        console.log(credentials)
        if (!credentials.user.uid) {
            payload({type: 1, value: 'Error creating user'})
            return
        }
        let newUser = {
            Email: email,
            Id: credentials.user.uid
        }
        Firebase.database().ref(`${paths.Users}/${credentials.user.uid}`).set(newUser, () => {
            payload({type: 0, value: 'User Registered'})
        }).catch(error => payload({type: 1, value: error.message}))

    }).catch(error => payload({type: 1, value: error.message}))
}

export function logIn(email, password, payload) {
    Firebase.auth().signInWithEmailAndPassword(email, password).then(response => {
        Firebase.database().ref(`${paths.Users}/${response.user.uid}`).once('value').then(snapshot => {
            payload({type: 0, value: snapshot.val()})
        }).catch(error => payload({type: 1, value: error.message}))
    }).catch(error => {
        payload({type: 1, value: error.message})
        console.log(error)
    })
}

export function getObject(id, callback) {
    Firebase.database().ref(`${paths.Objects}/${id}`).once('value').then(snapshot => {
        callback(snapshot.val())
    })
}

export default Firebase;