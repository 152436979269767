import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { getObject } from './../Logic/Firebase'
import styled, { css } from 'styled-components'

const Holder = styled.div`
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
`

const Panel = styled.div`
    position: absolute;
    bottom: 1em;
    right: ${p => p.state ? '1em' : '-15em'};

    border-radius: ${p => p.theme.roundCorners};
    background-color: ${p => p.theme.light};
    overflow: hidden;

    width: 15em;
    transition: .3s;
`

const Thumbnail = styled.div`
    ${p => p.assetId && css`
        background-image: url(${'https://firebasestorage.googleapis.com/v0/b/inviewer-89ad5.appspot.com/o/Images%2F' + p.assetId +'?alt=media'});
        background-size: cover;
    `}

    height: 15em;
`

const Name = styled.div`
    color: ${p => p.theme.dark};
    margin: 1em;
    font-weight: bold;
`

const FirstPersonView = forwardRef((props, ref) => {
    const [state, reducer] = useState({
        unityState: { State: false, AssetId: null },
        setUnityState: u => { state.unityState = u; state.save(); },
        object: {},
        setObject: o => {state.object = o; state.save(); },
        save: () => reducer({...state})
    })

    useImperativeHandle(ref, () => ({
        onUnityMessage(message) {
            if (message.Type === 'FirstPersonPanel') {
                let unityState = JSON.parse(message.Data)
                state.setUnityState(unityState)
                getObject(unityState.AssetId, state.setObject)
            }
        }
    }))

    const getText = () => {
        if (!state.object) return ''
        let text = state.object.Name
        if (state.object.Price) text += ' : $' + state.object.Price
        return text
    }

    return (
        <Holder>
            <Panel state={state.unityState.State==='True'}>
                <Name>{getText()}</Name>
                <Thumbnail assetId={state.unityState.AssetId}/>
            </Panel>
        </Holder>
    )
})

export default FirstPersonView;