import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    width: 100%;
    margin: .3em;
    padding: 1em;
    border: none;
    border-radius: ${props => props.theme.roundCorners};
    box-shadow: ${props => props.theme.shadow};
    font-size: ${props => props.theme.titleSize};
    box-sizing: border-box;
`

export default function InputField(props) {
    const onChangeHandler = event => {
        if (props.onChange) props.onChange(event.target.value)
    }

    return (
        <Input 
            id={props.id}
            type={props.type}
            onChange={onChangeHandler}
            // value={props.value}
            placeholder={props.placeholder}/>
    )
}