import React, { forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'

import IconButton from './../Components/IconButton'

const Holder = styled.div`
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
`

const ButtonHolder = styled.div`
    pointer-events: auto;
    background-color: ${p => p.theme.light};
    border-radius: 3em;
    position: absolute;
    top: 1em;
    left: 1em;
    width: 3em;
`

const DownBottonHolder = styled.div`
    pointer-events: auto;
    background-color: ${p => p.theme.light};
    border-radius: 3em;
    position: absolute;
    bottom: 1em;
    left: 1em;
    width: 3em;
`

const EditorView = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onUnityMessage(message) {

        }
    }))

    const setTool = tool => {
        props.sendToUnity('EditorToolUI', tool)
    }

    return (
        <Holder>
            <ButtonHolder>
                <IconButton onClick={() => setTool('Move')} style={{height: '3em'}} icon='open_with'/>
                <IconButton onClick={() => setTool('Rotate')} style={{height: '3em'}} icon='cached'/>
                <IconButton onClick={() => setTool('Scale')} style={{height: '3em'}} icon='transform'/>
            </ButtonHolder>
            <DownBottonHolder>
                <IconButton onClick={props.homeHandler} style={{height: '3em'}} icon='home'/>
            </DownBottonHolder>
        </Holder>
    )
}) 

export default EditorView;